import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>
        Build Tools</h2>
      <p>
        <b>We Shape Our Tools, and Thereafter Our Tools Shape Us</b>. <br />
        With this in mind we want to build tools that will speed up this process.
      </p>
      <ul className="actions">
        <li>
          <a href="https://alpha.app.learningpaths.io/#/register" target="_blank" className="button">
            Learn More
                  </a>
        </li>
      </ul>
    </section>
    <section>
      <h2>Contact Us</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>Kumaraswamy Layout &bull; Bangalore &bull; Inida</dd>
        <dt>Twitter</dt>
        <dd><a
          href="https://twitter.com/learningpathsio"
          target="_blank">@gokulnk</a></dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:learningpaths.io@gmail.com">learningpaths.io@gmail.com</a>
        </dd>
      </dl>
      <ul className="icons">
        <li>
          <a
            href="https://twitter.com/learningpathsio"
            className="icon fa-twitter alt" target="_blank">
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/learningpathsio" className="icon fa-facebook alt" target="_blank">
            <span className="label">Facebook</span>
          </a>
        </li>
        {/* <li>
          <a href="https://codebushi.com" className="icon fa-instagram alt" target="_blank">
            <span className="label">Instagram</span>
          </a>
        </li> */}
        {/* <li>
          <a
            href="https://github.com/codebushi/gatsby-starter-stellar"
            className="icon fa-github alt"
          >
            <span className="label">GitHub</span>
          </a>
        </li> */}
        <li>
          <a href="https://medium.com/learning-paths-io" className="icon fa-dribbble alt" target="_blank">
            <span className="label">Medium</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">
      &copy;  <a href="https://twitter.com/gokulnk">@gokulnk</a>.
    </p>
  </footer>
)

export default Footer
